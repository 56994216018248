@use 'mixins/media-queries.scss' as *;
@use 'mixins/center.scss';

#navbar-list {
    position: fixed;
    width: 100%;
    background: var(--clr-bg);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform .4s;
    z-index: 1000;

    @include minW(40rem) {
        position: absolute;
        min-height: 3.25rem;
        background-color: var(--clr-transparent);
        transform: scaleY(1);
    }

    i {
        font-size: 1.1em;
        padding-right: .4em;
        vertical-align: middle;
    }

    ul {
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        margin: 0;
        padding: .5rem 1rem;
        transition: opacity .15s ease-in-out 0s;

        @include minW(40rem) {
            opacity: 1;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    li {
        display: inline;
    }

    a {
        display: block;
        position: relative;
        margin: .7rem;
        color: var(--clr-light);
        text-decoration: none;
        font-size: 1rem;

        @include minW(40rem) {
            margin: 1rem;
        }

        &:focus {
            outline: none;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: .125rem;
            bottom: -.3rem;
            left: 0;
            transform: scaleX(0);
            background-color: var(--clr-light);
            transition-duration: .3s;
        }

        &:hover::after,
        &:focus::after {
            transform: scaleX(1);
        }
    }
}

/* ---- NAVBAR OPEN ----- */
#nav-checkbox:checked ~ #navbar-list {
    transform: scaleY(1);

    ul {
        opacity: 1;
        transition: opacity .2s ease-in-out .2s;
    }
}

/* --- HAMBURGER CLOSED --- */
#nav-checkbox {
    display: none;

    ~ label {
        position: fixed;
        width: 2rem;
        height: 2rem;
        top: 1rem;
        right: 1rem;
        z-index: 1001;

        @include minW(40rem) {
            display: none;
        }
    }
}

#hamburger-content {
    width: 100%;
    height: 100%;
    position: relative;
    @include center.children;
}

#hamburger-middle {

    &,
    &::before,
    &::after {
        content: '';
        height: .125rem;
        width: 2rem;
        border-radius: .0625rem;
        background: var(--clr-light);
        transform-origin: center;
        transition-duration: .3s;
    }

    &::before {
        position: absolute;
        left: 0;
        bottom: .5rem;
    }

    &::after {
        position: absolute;
        left: 0;
        top: .5rem;
    }
}

/* ---- HAMBURGER OPEN ---- */
#nav-checkbox:checked ~ label #hamburger-middle {
    width: 0;

    &::before {
        transform: rotate(45deg) translate(-.3125rem, -.3125rem);
    }

    &::after {
        transform: rotate(-45deg) translate(-.3125rem, .3125rem);
    }
}
